import React from 'react';
import { Box, Dialog, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import YouTube from "react-youtube";
import { isMobile } from 'react-device-detect';

import { SupportPopupOptions } from './TeachersSupport';

import './SupportVideoPopup.css';

export interface SupportVideoPopupProps {
    popupOpen: boolean;
    closePopup: () => void;
    popupOptions: SupportPopupOptions
}

export default function SupportVideoPopup(props: SupportVideoPopupProps) {
    const {
        popupOpen,
        closePopup,
        popupOptions
    } = props;

    const youtubeVideoOptions = {
        width: '640',
        playerVars: {
            controls: 1,
        }
    };

    const youtubeVideoOptionsMobile = {
        width: '300'
    }


    return (
        <Dialog 
            open={popupOpen} 
            onClose={closePopup}
            maxWidth={'lg'}
        >
            <DialogTitle className='bold-text'>{popupOptions.title}</DialogTitle>
            <IconButton
                aria-label="close"
                onClick={closePopup}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: 'grey',
                }}
                >
                <CloseIcon />
            </IconButton>
            <DialogContent className='support-video-popup-content'>
                <Typography className='support-video-description'>{popupOptions.description}</Typography>
                <Box>
                    <YouTube 
                        videoId={popupOptions.videoId}
                        id={popupOptions.id}
                        opts={isMobile ? youtubeVideoOptionsMobile : youtubeVideoOptions}
                    />
                </Box>
            </DialogContent>
        </Dialog>
    )
}