import React, {Component, useState} from 'react';

import { Box, Card, CardActionArea, Grid, Typography } from '@mui/material';
import { isMobile } from 'react-device-detect';
import StarIcon from '@mui/icons-material/Star';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import RewardIcon from '@mui/icons-material/EmojiEvents';
import TimelineIcon from '@mui/icons-material/Timeline';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import * as Sentry from "@sentry/react";

import Logo from '../../../images/hilight_hi_logo.png';

import './TeacherSupport.css';
import SupportVideoPopup from './SupportVideoPopup';

export interface SupportPopupOptions {
    id: string,
    videoId: string,
    title: string,
    description: string,
    icon: JSX.Element
};

export interface TeacherSupportProps {
    hideRewards: boolean
}

export function TeachersSupport(props: TeacherSupportProps) {
    const { hideRewards} = props;

    let supportPopupOptions: SupportPopupOptions[] = [
        {
            id: 'intro',
            videoId: 'IAzGxBZbutg',
            title: 'An introduction to Hilight',
            description: 'Learn about the research behind what makes our tool so impactful',
            icon: <AutoAwesomeIcon className='teacher-support-icon'/>
        },
        {
            id: 'send-first',
            videoId: 'Q051_t0nIyQ',
            title: 'Sending your first Hilight',
            description: 'What you need to know about sending a hilight',
            icon: <StarIcon className='teacher-support-icon'/>
        },
        {
            id: 'rewards',
            videoId: 'Ul8816a1pYE',
            title: 'Rewards Marketplace',
            description: 'How hilight rewards points work and how to redeem them in the Rewards Marketplace',
            icon: <RewardIcon className='teacher-support-icon'/>
        },
        {
            id: 'data',
            videoId: '7dmTr9uYrdc',
            title: 'Data Dashboard',
            description: 'Use the Data Dashboard to gain insight into your strengths and your impact',
            icon: <TimelineIcon className='teacher-support-icon'/>
        },
        {
            id: 'iphone',
            videoId: 'ON-yQMRSbUE',
            title: 'Saving Hilight to your phone',
            description: 'Save Hilight as an app on your mobile device to make it easier and faster to use',
            icon: <PhoneIphoneIcon className='teacher-support-icon'/>
        }
    ];
    if (hideRewards) {
        supportPopupOptions = supportPopupOptions.filter(popup => popup.id !== 'rewards');
    }

    const [supportVideoPopupOpen, setSupportVideoPopupOpen] = useState(false);
    const closeSupportVideoPopup = () => {
        setSupportVideoPopupOpen(false);
    }
    const [selectedSupportVideoOptions, setSelectedSupportVideoOptions] = useState(supportPopupOptions[0])

    const handleOnClick = (popupId: string): void => {
        const popupOptions = supportPopupOptions.find(popup => popup.id === popupId);
        if (!popupOptions) {
            Sentry.captureException(`No support popup found for id ${popupId}`);
            return;
        }
        setSelectedSupportVideoOptions(popupOptions);
        setSupportVideoPopupOpen(true);
    }

    return (
        <div className='teacher-support-page'>
            <Box className='page-heading'>Support</Box>
            <SupportVideoPopup 
                popupOpen={supportVideoPopupOpen}
                closePopup={closeSupportVideoPopup}
                popupOptions={selectedSupportVideoOptions}
            />
            <Grid container spacing={3} className={`teacher-support-grid ${isMobile ? 'teacher-support-grid-mobile' : ''}`}>
                <Grid item xs={8} md={8} lg={8} className='teacher-support-grid-item-border'>
                    <Typography variant='h6' className='bold-text'>Getting Started</Typography>
                    <Typography>What we recommend for getting started and making the most out of Hilight.</Typography>
                    <Box className='support-sub-grid'>
                        {supportPopupOptions.map((popupOption) => {
                            return ( 
                                <Card className='support-card-border'>
                                    <CardActionArea
                                        onClick={() => handleOnClick(popupOption.id)}
                                        className='support-card-action-area'
                                    >
                                        <Box className='support-card-content'>
                                            <Typography className='bold-text'>{popupOption.title}</Typography>
                                            <Box>
                                                {popupOption.icon}
                                            </Box>
                                            <Typography className='teacher-support-sub-text'>{popupOption.description}</Typography>
                                        </Box>
                                    </CardActionArea>
                                </Card>
                            );
                        })}
                    </Box>
                </Grid>
                <Grid item xs={3} md={3} lg={3} className='teacher-support-grid-item-border teacher-main-support-box'>
                    <Typography className='support-questions'>
                        Need help? <br/>
                        Have a question about hilight? <br/>
                        Want to provide feedback? <br/>
                    </Typography>
                    <Box
                        component="img"
                        className='logo'
                        alt="hilight logo."
                        src={Logo}
                    />
                    <Typography className='support-instructions'>
                        Send an email to <b>support@hilightedu.com</b>, and we will get back to you as soon as possible.</Typography>
                </Grid>
                
            </Grid>
        </div>
    )
}