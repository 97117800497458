import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Button, CircularProgress, List, ListItem, Pagination, Typography } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import * as Sentry from "@sentry/react";

import { RecognitionData } from '../../../../../src/types/recognition';
import FeedListItem from './FeedListItem';
import { MainServiceApi } from '../../../services/mainService';
import { authSelector } from '../../../store/authSlice';
import { RecognitionType } from '../../../util/type';
import ExportPopup from './ExportPopup';

import './FeedList.css';

export interface FeedListProps {
    schoolId?: number;
    districtId?: number;
    userId: number;
    recognitionType: RecognitionType;
    startDate: Date;
    endDate?: Date;
    pageSize?: number;
    disabledForFreeUser?: boolean;
    hideCountText?: boolean;
}

export default function FeedList(props: FeedListProps) {
    const { schoolId, districtId, userId, recognitionType, startDate, endDate, pageSize, disabledForFreeUser, hideCountText } = props;

    // Default page size is 15
    const actualPageSize = pageSize || 15;

    // State for pages of recognition
    const [recognitionList, setRecognitionList] = useState<RecognitionData[] | undefined>(undefined);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [hilightCount, setHilightCount] = useState(0);

    // Data store
    const authDataStore = useSelector(authSelector);
    const { token } = authDataStore;

    // Export popup
    const [showExportPopup, setShowExportPopup] = useState(false);

    // Remove Hilight
    const handleRemoveHilight = () => {
        getRecognitionPage(currentPage); 
    }

    // Text at the top of the list
    let hilightCountText = '';
    switch(recognitionType) {
        case RecognitionType.District:
            hilightCountText = 'Your District has sent';
            break;
        case RecognitionType.School:
            hilightCountText = 'Together, you and your colleagues have sent';
            break;
        case RecognitionType.UserReceived:
            hilightCountText = 'You have received';
            break;
        case RecognitionType.UserSent:
            hilightCountText = 'You have sent';
            break;
        case RecognitionType.Student:
            hilightCountText = 'Your students have received';
            break;
    }

    const getRecognitionPage = async (page: number) => {
        const mainServiceApi = MainServiceApi();
        try {
            const recognitionResponse = await mainServiceApi.getRecognitionPaginated({
                userId,
                page,
                pageSize: actualPageSize,
                recognitionType,
                token: token!,
                schoolId: schoolId,
                districtId,
                startDate,
                endDate
            });

            if (recognitionResponse.recognition) {
                setRecognitionList(recognitionResponse.recognition);
                setCurrentPage(recognitionResponse.currentPage);
                setTotalPages(recognitionResponse.totalPages);
                setHilightCount(recognitionResponse.count);
            } else {
                setRecognitionList([]);
                setCurrentPage(1);
                setTotalPages(1);
                setHilightCount(0);
            }
            
        } catch (err) {
            // log the error
			Sentry.captureException(err);
        }
    }

    useEffect(() => {
        getRecognitionPage(currentPage);
    }, [schoolId, recognitionType, startDate, endDate])

    const handleChangePage = async (newPage: number): Promise<void> => {
        // Scroll to the top of the page
        const element = document.getElementById('main-box');
        element?.scrollTo(0,0);

        // Load new recognition
        await getRecognitionPage(newPage);
    }
    
    return (
        <Box className='paginated-feed-list-box'>
            {schoolId && districtId &&
                <ExportPopup 
                    schoolId={schoolId} 
                    districtId={districtId}
                    userId={userId}
                    popupOpen={showExportPopup} 
                    closePopup={() => {setShowExportPopup(false)}}
                />
            }
            {recognitionType === RecognitionType.Student &&
                <div>
                    <Typography className='student-feed-permission-text'>
                        <span className='feed-yellow-bold-text'>Note:</span> The Student Feed is controlled by a special permission assigned at the user-level. Only certain users with the assigneed permission will be able to view this feed. Please reach out to the Hilight Support Team if you need to add the permission for additional members of your staff.
                    </Typography>
                </div>
            }
            {hilightCount > 0 && 
                <div className='feed-list-header'>
                    {!hideCountText &&
                        <div className='hilight-count-text'>
                            <Typography>
                                {hilightCountText } <span className='feed-yellow-bold-text'>{hilightCount}</span> {<span>{hilightCount === 1 ? ' hilight' : ' hilights'}</span>} this school year!
                            </Typography>
                        </div>
                    }
                    { recognitionType === RecognitionType.Student &&
                        <div className='export-button'>
                            <Button
                                variant='outlined'
                                startIcon={<DownloadIcon/>}
                                size='small'
                                className='feed-list-export-button'
                                onClick={() => setShowExportPopup(true)}
                            >Export</Button>
                        </div>
                    }
                </div>
            }
            {disabledForFreeUser ?
                <div>
                    <Typography>The School feed is only enabled when your school or district has purchased the premium version of Hilight. Want to learn more or request more information? Visit our <a href='https://www.hilightedu.com'>website</a>. </Typography>
                </div>

                : 

                recognitionList === undefined ? (<CircularProgress />) : 
                    (recognitionList!.length > 0 ?
                        <div>
                            <List>
                                {
                                    recognitionList!.map((recognitionItem) => 
                                        <ListItem key={recognitionItem.id}>
                                            <FeedListItem 
                                                recognitionData={recognitionItem}
                                                recognitionType={recognitionType}
                                                hilightRemoved={handleRemoveHilight}
                                            />
                                        </ListItem>
                                    )
                                }	
                            </List> 
                                {totalPages > 1 ?
                                    <div className='feed-list-pagination'>
                                        <Pagination 
                                            count={totalPages}
                                            page={currentPage}
                                            color="secondary"
                                            variant="outlined"
                                            onChange={(event, page) => handleChangePage(page)}
                                        />
                                    </div> : <></>
                                }
                        </div>
                        : <Typography className='no-data'>No data to display - start sending some hilights!</Typography>
                    )
            }
        </Box> 
    )
}