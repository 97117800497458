import React from 'react';
import * as Sentry from "@sentry/react";
import { isMobile } from 'react-device-detect';

import { TokenTypeDisplayData } from '../../../../../src/types/recognition';
import CajonCoreValue1 from '../../../images/customizations/cajon/cajon_core_value_1.png';
import CajonCoreValue2 from '../../../images/customizations/cajon/cajon_core_value_2.png';
import CajonCoreValue3 from '../../../images/customizations/cajon/cajon_core_value_3.png';
import CajonCoreValue4 from '../../../images/customizations/cajon/cajon_core_value_4.png';
import CajonCoreValue5 from '../../../images/customizations/cajon/cajon_core_value_5.png';
import CajonCoreValue6 from '../../../images/customizations/cajon/cajon_core_value_6.png';
import CajonCoreValue7 from '../../../images/customizations/cajon/cajon_core_value_7.png';
import CajonCoreValue8 from '../../../images/customizations/cajon/cajon_core_value_8.png';


import { Box, Typography } from '@mui/material';

import './CajonRecognitionFormCustomizations.css';

export const CajonCoreValueMappings: {tokenCoreValue: string, imagePath: string}[] = [
    {
        tokenCoreValue: 'Be Cajon Valley',
        imagePath: CajonCoreValue1
    },
    {
        tokenCoreValue: 'Empower Staff',
        imagePath: CajonCoreValue2
    },
    {
        tokenCoreValue: 'Emphasize Relationships',
        imagePath: CajonCoreValue3
    },
    {
        tokenCoreValue: 'Build Community',
        imagePath: CajonCoreValue4
    },
    {
        tokenCoreValue: 'Communicate Positively',
        imagePath: CajonCoreValue5
    },
    {
        tokenCoreValue: 'Deliver as a Team',
        imagePath: CajonCoreValue6
    },
    {
        tokenCoreValue: 'Innovate and Develop',
        imagePath: CajonCoreValue7
    },
    {
        tokenCoreValue: 'Deliver Impeccable Service',
        imagePath: CajonCoreValue8
    },
];

// Leave for testing locally
// export const CajonCoreValueMappings: {tokenCoreValue: string, imagePath: string}[] = [
//     {
//         tokenCoreValue: 'Teamwork',
//         imagePath: CajonCoreValue1
//     },
//     {
//         tokenCoreValue: 'Excellence',
//         imagePath: CajonCoreValue2
//     },
//     {
//         tokenCoreValue: 'Innovation',
//         imagePath: CajonCoreValue3
//     },
//     {
//         tokenCoreValue: 'Leadership',
//         imagePath: CajonCoreValue4
//     },
//     {
//         tokenCoreValue: 'Joy',
//         imagePath: CajonCoreValue5
//     },
//     {
//         tokenCoreValue: 'X-Factor',
//         imagePath: CajonCoreValue6
//     },
//     {
//         tokenCoreValue: 'Innovate and Develop',
//         imagePath: CajonCoreValue7
//     },
//     {
//         tokenCoreValue: 'Deliver Impeccable Service',
//         imagePath: CajonCoreValue8
//     },
// ];

export interface CajonCoreValueProps {
    tokenType: TokenTypeDisplayData,
    isSelected: boolean,
    setSelected: (tokenId: number) => void;
}

export default function CajonCoreValue(props: CajonCoreValueProps) {
    const {
        tokenType,
        isSelected,
        setSelected
    } = props;

    const cajonCoreValue = CajonCoreValueMappings.find(coreValue => coreValue.tokenCoreValue === tokenType.tokenCoreValue)
    if (!cajonCoreValue) {
        // log the error if no image found
        Sentry.captureException(`No image found for cajon core value. Core Value id: ${tokenType.id}`);
    }
    return  (
        <Box 
            className={`cajon-promise-border ${isSelected && ' cajon-promise-selected'}`}
            onClick={() => setSelected(tokenType.id)}
        >
            {
                !cajonCoreValue ?
                    // Show something by default if no image found
                    <Box
                        className={`cajon-core-value-default${isMobile ? '-mobile' : ''}`}
                    >
                        <Typography>{`${tokenType.tokenCoreValue} - ${tokenType.tokenDisplay}`}</Typography>
                    </Box>
                :

                <Box
                    component="img"
                    className={`cajon-core-value-img${isMobile ? '-mobile' : ''}`}
                    alt="cajon-promise"
                    src={cajonCoreValue.imagePath}
                />
            }
            

        </Box>
    )
}