
import React,  { useRef } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DownloadIcon from '@mui/icons-material/Download';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import * as Sentry from "@sentry/react";

import CertificateBackground from '../../../images/customizations/cajon_certificate.png';
import { RecognitionData } from '../../../../../src/types/recognition';
import { CajonCoreValueMappings } from '../Customizations/CajonRecognitionFormCustomizations';

import './CertificatePopup.css';

export interface CertificatePopupProps {
    popupOpen: boolean;
    closePopup: () => void;
    recognitionData: RecognitionData
}

export default function CertificatePopup(props: CertificatePopupProps) {
    const {
        popupOpen,
        closePopup,
        recognitionData
    } = props;

    const handleClosePopup = () => {
        closePopup();
    }

    // Downloading the certificate
    const printRef = useRef(null);

    const handleDownloadPdf = async () => {
        const element = printRef.current;
        if (!element) {
            return;
        }

        const canvas = await html2canvas(element, {
            scale: 4
        });
        const data = canvas.toDataURL('image/png');

        const pdf = new jsPDF({
            orientation: 'portrait',
            unit: 'px',
            format: 'a4'
        });

        const imgProperties = pdf.getImageProperties(data);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;

        pdf.addImage(data, 'PNG', 0, 0, pdfWidth, pdfHeight);
        pdf.save('your_MVP_certificate.pdf');
    }

    // Get the correct MVP image
    const cajonCoreValue = CajonCoreValueMappings.find(coreValue => coreValue.tokenCoreValue === recognitionData.tokenType.tokenCoreValue);
    if (!cajonCoreValue) {
        // log the error if no image found
        Sentry.captureException(`No image found for cajon core value. Core Value id: ${recognitionData.tokenType.id}`);
    }

    // Choose font size based on length of text - probably ways to do this automatically
    let fontSizeHilightText = '16px';
    if (recognitionData.text) {
        if (700 < recognitionData.text.length && recognitionData.text.length <= 1001) { // max character length is 1000s
            fontSizeHilightText = '10px';
        } else if (500 < recognitionData.text.length && recognitionData.text.length <= 700) {
            fontSizeHilightText = '12px';
        } else if (100 <= recognitionData.text.length && recognitionData.text.length <= 500) {
            fontSizeHilightText = '14px';
        } else{ 
            fontSizeHilightText = '16px';
        }
    }

    // Choose font size of name based on length of text
    let fontSizeReceiver = 30;
    const lenghOfFullName = recognitionData.userReceiverName.firstName.length + recognitionData.userReceiverName.lastName.length;
    if (lenghOfFullName > 20) {
        fontSizeReceiver = 18;
    }
    const fontSizeSender = fontSizeReceiver - 4;

    return (
        <Dialog 
            open={popupOpen} 
            onClose={handleClosePopup}
            className='certificate-popup'
            fullWidth
            maxWidth='md'
        >
            <DialogTitle>Your MVP Hilight Certificate!</DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClosePopup}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: 'grey',
                }}
                >
                <CloseIcon />
            </IconButton>
            <DialogContent className='certificate-popup-content'>
                <Box ref={printRef} className='certificate-content'>
                    <Box className='certificate-outer-box'>
                        <Box
                            className='certificate-inner-box'
                        >
                            <img src={CertificateBackground} alt="certificate-background" className='certificate-image'  />
                            <Box className='certificate-interior-box'>
                                <Box className='certificate-interior'>
                                    <Box className='certificate-name '>
                                        <Typography className='certificate-bold-text' sx={{fontSize: fontSizeReceiver}}> {`${recognitionData.userReceiverName.firstName.toUpperCase()} ${recognitionData.userReceiverName.lastName.toUpperCase()}`} </Typography>
                                    </Box>
                                    <Box className='certificate-sender'>
                                        <Typography className='certificate-bold-text' sx={{fontSize: fontSizeSender}}>{`${recognitionData.userSenderName.firstName.toUpperCase()} ${recognitionData.userSenderName.lastName.toUpperCase()}`}</Typography>
                                    </Box>
                                    <Box className='cerificate-core-value'>
                                        {
                                            !cajonCoreValue ?
                                                <Box>
                                                    <Typography>{recognitionData.tokenType.tokenCoreValue}</Typography>
                                                </Box>
                                            :
                                                <Box
                                                    component="img"
                                                    className='certificate-core-value-image'
                                                    alt="cajon-promise"
                                                    src={cajonCoreValue.imagePath}
                                                />
                                        }
                                    </Box>
                                    <Box className='certificate-hilight-text'>
                                        <Typography sx={{fontSize: fontSizeHilightText}} >{recognitionData.text}</Typography>
                                    </Box>
                                    <Box className='certificate-date'>
                                        <Typography className='certificate-bold-text'>{`Date: ${new Date(recognitionData.created).toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' })}`}</Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>

            </DialogContent>
            <DialogActions>
                <Button 
                    startIcon={<DownloadIcon />}
                    variant='contained'
                    onClick={handleDownloadPdf}
                >
                    Download Certificate
                </Button>
            </DialogActions>
        </Dialog>
    )
}