import React from 'react';
import Box from '@mui/material/Box';
import { isMobile } from 'react-device-detect';

import Typography from '@mui/material/Typography';
import Cajon365 from  '../../../images/customizations/cajon/Cajon_365_vertical.png'

import './CajonRewards.css'
import Button from '@mui/material/Button';

export function CajonRewards() {

    return (
        <div className='rewards-page'>
            <Box className='page-heading'>Rewards</Box>
            <div className='cajon-rewards'>
                <Box className={`cajon-rewards-border${isMobile ? '-mobile' : ''}`}>
                    <Typography variant='h6' className='bold-text'>Redeeming your points in Cajon 365 Marketplace</Typography>
                    <Typography className='cajon-rewards-text'>Every time you send a hilight, you earn <span className='cajon-rewards-bold-text'>25 points</span>, and every time you receive a hilght, you earn 
                    <span className='cajon-rewards-bold-text'> 50 points</span> to use in the Cajon 365 Marketplace!</Typography>
                    <Box  
                        component="img"
                        className={`cajon-reward-img${isMobile ? '-mobile' : ''}`}
                        alt="cajon-365"
                        src={Cajon365}
                    />
                    <Typography>{`Your points get carried over to Cajon 365 at the end of every week, so you'll see them there by the following Monday!`}</Typography>
                    <Button 
                        variant='contained' 
                        className='cajon-rewards-button'
                        component="a"
                        href='https://app.alludolearning.com/districts/236-cajon-valley-union-school-district/rewards'
                        target='_blank'
                    >Click here to go to Cajon 365 now!</Button>
                </Box>
            </div>
        </div>
    )
}