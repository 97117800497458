import * as React from 'react';
import { OverviewMetrics } from '../../../../../src/types/dataDashboard';
import { Box, Typography } from '@mui/material';

import './OverviewMetrics.css';

//1) number of hiliights sent total
//2) % of staff who have sent at least one hilight
//3) % of staff who have received at least one hilight

export interface OverviewMetricsVisualProps {
    data: OverviewMetrics;
    districtOrSchool: string;
}

export function OverviewMetricsVisual(props: OverviewMetricsVisualProps) {
    const { 
        data,
        districtOrSchool
    } = props;

    return (
        <Box className='overview-metrics'>
            <Box className='overview-metrics-item-border'>
                <Typography className='overview-metrics-item-text'>
                    {`Your ${districtOrSchool} has sent`}
                </Typography>
                <Typography className='overview-metrics-number'>
                    {data.hilightCount}
                </Typography>
                <Typography className='overview-metrics-item-text'>hilights this school year</Typography>
            </Box>
            <Box className='overview-metrics-item-border'>
                <Typography className='overview-metrics-number'>
                    {Math.round(data.percentSent*100)}%
                </Typography>
                <Typography className='overview-metrics-item-text'>
                    {`percent of staff have sent at least one hilight`}
                </Typography>
            </Box>
            <Box className='overview-metrics-item-border'>
                <Typography className='overview-metrics-number'>
                    {Math.round(data.percentReceived*100)}%
                </Typography>
                <Typography className='overview-metrics-item-text'>
                    {`percent of staff have received a hilight`}
                </Typography> 
            </Box>
        </Box>
    );
}