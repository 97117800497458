import React, { useEffect, useState } from 'react';
import { Autocomplete, Box, CircularProgress, FormControl, Grid, MenuItem, Tab, TextField, Typography } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import * as Sentry from "@sentry/react";

import { StandardUserData } from './StandardUserData';
import { GetAdminDataStaffChart, GetTeacherDataDashboard, OverviewMetrics } from '../../../../../src/types/dataDashboard';
import { StaffCoreValuesChart } from './CoreValuesChart/StaffCoreValuesChart';
import { School } from '../../../../../src/types/user';
import { useAppSelector } from '../../../store/hooks';
import { authSelector } from '../../../store/authSlice';
import { MainServiceApi } from '../../../services/mainService';
import { TokenTypesChartLegendItem, formatTokenTypes } from './Data';
import { PermissionServiceApi } from '../../../services/permissionService';
import { SchoolYear } from '../../../../../src/util/dates';
import { DistrictAdminData } from './DistrictAdminData/DistrictAdminData';

import './AdminData.css';
import { OverviewMetricsVisual } from './OverviewMetrics';

export interface AdminDataProps {
    userData: GetTeacherDataDashboard,
    initialTokenTypesChartLegend: TokenTypesChartLegendItem[],
    currentSchool?: School,
    disabledForFreeUser?: boolean,
    // The following are for the school year drop down on the Personal tab
    personalSchoolYearOptions?: SchoolYear[],
    personalHandleChangeSchoolYearSelection: (newValue?: string) => void,
    personalSelectedSchoolYear?: string,
}

export function AdminData(props: AdminDataProps) {
    const { 
        userData, 
        initialTokenTypesChartLegend, 
        currentSchool, 
        disabledForFreeUser,
        personalSchoolYearOptions,
        personalSelectedSchoolYear,
        personalHandleChangeSchoolYearSelection
    } = props;

    const authDataStore = useAppSelector(authSelector);
    const { user, token } = authDataStore;
    const districtId = user!.districtId;

    const [staffChartData, setStaffChartData] = useState<GetAdminDataStaffChart>();
    const [overviewData, setOverviewData] = useState<OverviewMetrics>();

    const [tabValue, setTabValue] = useState('1');
    const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
    	setTabValue(newValue);
  	};

    // Admin data loading in progress
    const [staffChartLoading, setStaffChartLoading] = useState(true);
    const [overviewDataLoading, setOverviewDataLoading] = useState(true);

    // School drop down (only appears if user has the correct permission, otherwise just shows the user's curren school)
    const [selectedSchool, setSelectedSchool] = useState<School | undefined>(currentSchool);
    const [districtSchools, setDistrictSchools] = useState<School[]>([]);
    const [userHasPermissionToViewDistrictData, setUserHasPermissionToViewDistrictData] = useState(false);
    const [tokenTypesChartLegend, setTokenTypesChartLegend] = useState<TokenTypesChartLegendItem[]>(initialTokenTypesChartLegend);

    // If the passed in school changes, update the selected school
    useEffect(() => {
        setSelectedSchool(currentSchool);
    }, [currentSchool]);

    // School years for the drop down for the Admin tab (separate from the drop down on the personal tab)
    const [ selectedSchoolYear, setSelectedSchoolYear] = useState<string>('');
    const [ startDate, setStartDate ] = useState<Date>();
    const [ endDate, setEndDate ] = useState<Date | undefined>(); // leave empty by default so we get up to and including today
    const [ schoolYearOptions, setSchoolYearOptions] = useState<SchoolYear[]>([]);
    const getSchoolYearOptions = async (schoolId: number, token: string) => {
        try {
            const mainService = MainServiceApi();
            const schoolYears = await mainService.getSchoolYearsList({schoolId, token});
            setSchoolYearOptions(schoolYears);
            // By default, should be showing the current school year
            const currentSchoolYear = schoolYears.find(option => option.key === 'current');
            setSelectedSchoolYear('current');
            setStartDate(currentSchoolYear?.startDate);
            setEndDate(undefined) // By default will get up and including to today if left undefined

        } catch (err) {
            // log the error
            Sentry.captureException(err);
        }
    }
    // Only re-fetch the school year options if the school id changes
    useEffect(() => {
        if (currentSchool) {
            getSchoolYearOptions(currentSchool.id, token!);
        }
    }, [currentSchool]);
    // Define the handler for the drop down here so we can pass it down
    const handleChangeSchoolYearSelection = (newValue?: string) => {
        const newSelectedSchoolYear = schoolYearOptions.find(option  => option.key === newValue);
        if (newSelectedSchoolYear) {
            setSelectedSchoolYear(newSelectedSchoolYear.key);
            setStartDate(newSelectedSchoolYear.startDate);
            setEndDate(newSelectedSchoolYear.endDate);
        }
    }

    // Get data 
    // If the selected school or school year changes from the dropdowns in this admin dashboard, update the charts
    useEffect(() => {
        if (selectedSchool && user && token && startDate) {
            setSelectedSchool(selectedSchool);
            getOverviewData({
                schoolId: selectedSchool.id, 
                token: token!,
                startDate,
                endDate
            });
            getStaffChartData({
                schoolId: selectedSchool.id, 
                token: token!,
                startDate,
                endDate
            });
        }
    }, [selectedSchool, startDate, endDate]);

    const getStaffChartData = async (data: {
        schoolId: number, 
        token: string,
        startDate: Date,
        endDate?: Date
    }) => {
        setStaffChartLoading(true);
        try {
            const { 
                schoolId,
                token,
                startDate,
                endDate
            } = data;
            const mainServiceApi = MainServiceApi();
            const staffChartDataResult = await mainServiceApi.getAdminDataStaffChart({
                schoolId, 
                token: token!,
                startDate,
                endDate
            });
            if (staffChartDataResult) {
                setStaffChartData(staffChartDataResult);
                setTokenTypesChartLegend(formatTokenTypes(staffChartDataResult.tokenTypes));
            }
        } catch (err) {
            // log the error
            Sentry.captureException(err);
        }
        setStaffChartLoading(false);
    }

    const getOverviewData = async (data: {
        schoolId: number, 
        token: string,
        startDate: Date,
        endDate?: Date
    }) => {
        setOverviewDataLoading(true);
        try {
            const { 
                schoolId,
                token,
                startDate,
                endDate
            } = data;
            const mainServiceApi = MainServiceApi();
            const overviewDataResult = await mainServiceApi.getAdminDataSchoolOverview({
                schoolId, 
                token: token!,
                startDate,
                endDate
            });
            if (overviewDataResult) {
                setOverviewData(overviewDataResult.overviewMetrics);
            }
        } catch (err) {
            // log the error
            Sentry.captureException(err);
        }
        setOverviewDataLoading(false);
    }

    // Get the list of schools for the drop down
    const getDistrictSchools = async (districtId: number, token: string): Promise<void> => {
        const mainServiceApi = MainServiceApi();
        const districtSchools = await mainServiceApi.getDistrictSchools(districtId, token);
        setDistrictSchools(districtSchools);
    } 
    
    const getUserPermissions = async (userId: number, token: string): Promise<void> => {
        const permissionService = PermissionServiceApi();
        const userPermissions = await permissionService.getPermissionsForUser(user!.id, token);
        const districtDashboardViewKey = 'DISTRICT_DATA_DASHBOARD';
		const userHasDistrictViewPermission = userPermissions.find(permission => permission.permissionKey === districtDashboardViewKey);
        if (userHasDistrictViewPermission) {
            setUserHasPermissionToViewDistrictData(true);
            getDistrictSchools(districtId, token!);
        } else {
            setUserHasPermissionToViewDistrictData(false);
        }
    }

    // Get the user permissions on initial render
    useEffect(() => {
        try {
            getUserPermissions(user!.id, token!);
        } catch (err) {
            // log the error
			Sentry.captureException(err);
        }
        
    }, []);
   

    return (
        <Box className='admin-data-box'>
            <TabContext value={tabValue}>
                <Box className='admin-data-tabs'>
                    <TabList onChange={handleChangeTab} aria-label="admin dashboard tabs">
                        <Tab label="School Admin View" value='1' />
                        {userHasPermissionToViewDistrictData &&
                            <Tab label="District Admin View" value='2' /> 
                        }
                        <Tab label="Personal View" value='3' />
                    </TabList>
                </Box>
                <TabPanel value='1'>
                    <Box className='admin-data-dashboard-dropdowns'>   
                        {userHasPermissionToViewDistrictData &&
                            <Box className='data-dashboard-school-dropdown'>
                                <Autocomplete
                                    disablePortal
                                    id="data-dashboard-school-select"
                                    options={districtSchools}
                                    value={selectedSchool || null}
                                    getOptionLabel={(option) =>  option.name }
                                    renderInput={(params) =>  <TextField {...params} label="Select a School in your District" />}
                                    onChange={(event: any, value: School | null) => {
                                        setSelectedSchool(value || undefined);
                                    }}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                />
                                </Box>
                        }
                        {/* Only show the school year drop down for schools that have more than one year*/}
                        {schoolYearOptions && schoolYearOptions?.length > 1 &&
                            <Box className='data-dashboard-year-dropdown'>
                                <FormControl className='school-year-dropdown'>
                                    <TextField
                                        value={selectedSchoolYear}
                                        onChange={(e) => handleChangeSchoolYearSelection(e.target.value)}
                                        label='Select a School Year'
                                        select
                                    >
                                        {schoolYearOptions.map((schoolYear) => <MenuItem key={schoolYear.key} value={schoolYear.key}>{schoolYear.displayText}</MenuItem>)}                           
                                    </TextField>
                                </FormControl> 
                            </Box>
                        }
                    </Box>
                    {user?.schools?.length && user.schools.length > 1 &&
                        <Typography className='admin-dashboard-subtitle'>{`This is the Admin view for ${currentSchool?.name}. If you'd like to view this dashboard for another school, use the drop down in the upper left to change schools. A District-wide View of this dashboard is coming soon!`}</Typography>
                    }
                    <div>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={12} className='admin-dashboard-grid-item'>
                                { overviewDataLoading || !overviewData ? 
                                        <CircularProgress />
                                    :
                                    <OverviewMetricsVisual 
                                        data={overviewData}
                                        districtOrSchool='School'
                                    />
                                }
                            </Grid>
                            <Grid item xs={12} md={12} className='admin-dashboard-grid-item'>
                                <div className='admin-dashboard-grid-item-border'>
                                    <div className='admin-dashboard-grid-item-content'>
                                        <h3 className='admin-dashboard-grid-item-header'>Staff Recognition</h3>
                                        {staffChartLoading || !staffChartData ?
                                            <CircularProgress />
                                        :
                                            <StaffCoreValuesChart 
                                                data={staffChartData} 
                                                dataLoading={staffChartLoading}
                                                chartLegend={tokenTypesChartLegend}
                                            />
                                        }
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                        
                    </div>
                </TabPanel>
                <TabPanel value='2'>
                    <DistrictAdminData 
                        districtId={districtId}
                    />
                </TabPanel>
                <TabPanel value='3'>
                    <StandardUserData 
                        data={userData}
                        tokenTypesChartLegend={tokenTypesChartLegend}
                        currentSchool={currentSchool}
                        disabledForFreeUser={disabledForFreeUser}
                        schoolYearOptions={personalSchoolYearOptions}
                        selectedSchoolYear={personalSelectedSchoolYear}
                        handleChangeSchoolYearSelection={personalHandleChangeSchoolYearSelection}
                    />
                </TabPanel>
            </TabContext>
        </Box>
    )
}

