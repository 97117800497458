import React, {useState, useEffect} from 'react';
import * as Sentry from "@sentry/react";

import { Box, CircularProgress, FormControl, Grid, MenuItem, TextField, Typography } from '@mui/material';
import { SchoolYear } from '../../../../../../src/util/dates';
import { MainServiceApi } from '../../../../services/mainService';
import { useAppSelector } from '../../../../store/hooks';
import { authSelector } from '../../../../store/authSlice';

import './DistrictAdminData.css';
import { OverviewMetricsVisual } from '../OverviewMetrics';
import { GetDistrictAdminSchoolChart, OverviewMetrics } from '../../../../../../src/types/dataDashboard';
import { SchoolTotalsChart } from './SchoolTotalsChart';

export interface DistrictAdminDataProps {
    districtId: number
}

export function DistrictAdminData(props: DistrictAdminDataProps) {
    const {
        districtId
    } = props;

    const authDataStore = useAppSelector(authSelector);
    const { token } = authDataStore;

    const [schoolChartData, setSchoolChartData] = useState<GetDistrictAdminSchoolChart>();
    const [schoolChartDataLoading, setSchoolChartDataLoading] = useState(true);
    const [overviewData, setOverviewData] = useState<OverviewMetrics>();
    const [overviewDataLoading, setOverviewDataLoading] = useState(true);

    const getSchoolChartData = async (data: {
        districtId: number,
        startDate: Date,
        endDate?: Date,
        token: string
    }) => {
        setSchoolChartDataLoading(true);
        try {
            const mainServiceApi = MainServiceApi();
            const response = await mainServiceApi.getDistrictAdminDataSchoolChart({
                districtId: data.districtId,
                token:  data.token,
                startDate: data.startDate,
                endDate: data.endDate
            });
            if (response) {
                setSchoolChartData(response);
            }
        } catch (err) {
            // log the error
            Sentry.captureException(err);
        }
        setSchoolChartDataLoading(false);
    }

    const getOverviewData = async (data: {
        districtId: number,
        startDate: Date,
        endDate?: Date,
        token: string
    }) => {
        setOverviewDataLoading(true);
        try {
            const mainServiceApi = MainServiceApi();
            const response = await mainServiceApi.getDistrictAdminDataOverview({
                districtId: data.districtId,
                token:  data.token,
                startDate: data.startDate,
                endDate: data.endDate
            });
            if (response) {
                setOverviewData(response.overviewMetrics);
            }
        } catch (err) {
            // log the error
            Sentry.captureException(err);
        }
        setOverviewDataLoading(false);
    }

    // School Year Drop Down
    const [districtSchoolYearOptions, setDistrictSchoolYearOptions] = useState<SchoolYear[]>([])
    const [selectedSchoolYear, setSelectedSchoolYear] = useState<string>('');
    const [ startDate, setStartDate ] = useState<Date>();
    const [ endDate, setEndDate ] = useState<Date | undefined>(); // leave empty by default so we get up to and including today
    const getDistrictSchoolYearOptions = async (districtId: number, token: string) => {
        try {
            const mainService = MainServiceApi();
            const schoolYears = await mainService.getDistrictSchoolYearsList({districtId, token});
            setDistrictSchoolYearOptions(schoolYears);
            const currentSchoolYear = schoolYears.find(option => option.key === 'current');
            setSelectedSchoolYear('current');
            setStartDate(currentSchoolYear?.startDate);
            setEndDate(undefined) // By default will get up and including to today if left undefined
        } catch (err) {
            // log the error
            Sentry.captureException(err);
        }
    }
    useEffect(() => {
        if (token) {
            getDistrictSchoolYearOptions(districtId, token);
        }
    }, [districtId]);

    // Define the handler for the drop down here so we can pass it down
    const handleChangeSchoolYearSelection = (newValue?: string) => {
        const newSelectedSchoolYear = districtSchoolYearOptions.find(option  => option.key === newValue);
        if (newSelectedSchoolYear) {
            setSelectedSchoolYear(newSelectedSchoolYear.key);
            setStartDate(newSelectedSchoolYear.startDate);
            setEndDate(newSelectedSchoolYear.endDate);
        }
    }

    useEffect(() => {
        if (token && startDate) {
            getOverviewData({districtId, token, startDate, endDate});
            getSchoolChartData({districtId, token, startDate, endDate});
        }
    }, [districtId, startDate, endDate]);
    
    return (
        <Box>
            <Box className='district-admin-data-dropdowns'>
                {districtSchoolYearOptions && districtSchoolYearOptions?.length > 1 &&
                    <Box className='data-dashboard-year-dropdown'>
                        <FormControl className='school-year-dropdown'>
                            <TextField
                                value={selectedSchoolYear}
                                onChange={(e) => handleChangeSchoolYearSelection(e.target.value)}
                                label='Select a School Year'
                                select
                            >
                                {districtSchoolYearOptions.map((schoolYear) => <MenuItem key={schoolYear.key} value={schoolYear.key}>{schoolYear.displayText}</MenuItem>)}                           
                            </TextField>
                        </FormControl> 
                    </Box>
                }
            </Box>
            <Box>
                <Box>
                    {overviewDataLoading || !overviewData ?
                            <CircularProgress /> 
                        :
                            <OverviewMetricsVisual 
                                data={overviewData}
                                districtOrSchool='District'
                            />  
                    }
                </Box> 
                <Box className='distrit-admin-data-chart'>
                    <h3 className='admin-dashboard-grid-item-header'>Total Hilights Sent by School</h3>
                    {schoolChartDataLoading || !schoolChartData ?
                            <CircularProgress />
                        :
                            <SchoolTotalsChart
                                schoolTotalsData={schoolChartData.schoolTotals}
                            />
                    }
                </Box>
            </Box>
            

        </Box>
    )
}