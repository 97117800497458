import React, {useState} from 'react';
import { Avatar, Box, Button, IconButton, Menu, MenuItem } from '@mui/material';
import { useSelector } from 'react-redux';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';

import { RecognitionData } from '../../../../../src/types/recognition';
import { timeAgo } from '../../../util/time';
import { getUserAvatar } from '../../../util/images';
import { RecognitionType, UserType } from '../../../util/type';
import { authSelector } from '../../../store/authSlice';
import RemoveHilightPopup from './RemoveHilightPopup';
import CertificatePopup from './CertificatePopup';
import { isCajonDistrict } from '../../../util/customizations';

import './FeedListItem.css';

export interface FeedListItemProps {
    recognitionData: RecognitionData,
    recognitionType: RecognitionType,
    hilightRemoved: () => void
}

export default function FeedListItem(props: FeedListItemProps) {
    const { 
        recognitionData, 
        recognitionType, 
        hilightRemoved
    } = props;

    const authDataStore = useSelector(authSelector);
    const { user } = authDataStore;

    const timeString = timeAgo.format(new Date(recognitionData.created));

    // Remove Hilight Popup
    const [showRemoveHilightPopup, setShowRemoveHilightPopup] = useState(false);

    // View Certificate Popup
    const [showCertificatePopup, setShowCertificatePopup] = useState(false);

    // More options menu
    const [showMoreOptionsMenu, setShowMoreOptionsMenu] = useState(user?.userType === UserType.Admin);
    const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(null);
    const ITEM_HEIGHT = 48;
    const menuOpen = Boolean(menuAnchorEl);
    const handleClickMenu = (event: React.MouseEvent<HTMLElement>) => {
        setMenuAnchorEl(event.currentTarget);
      };
    const handleCloseMenu = () => {
        setMenuAnchorEl(null);
    };
    const handleClickRemoveHilight = () => {
        // show the popup to confirm that this hilight should be removed
        setShowRemoveHilightPopup(true);
        handleCloseMenu();
    }

    // The cerificate button should only be visible if the district is Cajon 
    // and either it's the current user's hilight OR the user is Admin
    const certificateVisible = isCajonDistrict(user?.districtId)  && (user?.id === recognitionData.userIdReceiver || user?.userType === UserType.Admin);

    return (
        <div className='feed-list-item'>
            <RemoveHilightPopup 
                popupOpen={showRemoveHilightPopup}
                closePopup={() => setShowRemoveHilightPopup(false)}
                recognitionData={recognitionData}
                hilightRemoved={hilightRemoved}
            />
            {certificateVisible &&
                <CertificatePopup
                    popupOpen={showCertificatePopup}
                    closePopup={() => setShowCertificatePopup(false)}
                    recognitionData={recognitionData}
                />
            }
            <Box className='feed-list-item-header'>
                <Avatar
                        className='feed-list-item-avatar'
                        {...getUserAvatar(recognitionData.userReceiverProfilePhotoImageUrl)}
                >{recognitionData.userReceiverName.firstName[0]}{recognitionData.userReceiverName.lastName[0]}</Avatar>
                <Box className='feed-header-box'>
                    <Box>
                        <p className='feed-header-text'>
                            <span className='feed-header-bold'>
                                {recognitionData.userReceiverName.firstName} {recognitionData.userReceiverName.lastName} </span> was shouted out for 
                            <span className='feed-header-bold'> {recognitionData.tokenType.tokenShortDisplay }</span>
                        </p>
                        {recognitionType !== RecognitionType.School && <span className='feed-header-school-name'>{recognitionData.userReceiverName.schoolName}</span>}
                    </Box>
                </Box>
                {showMoreOptionsMenu && 
                    <Box className='feed-list-item-menu-options-box'>
                        <IconButton
                            aria-label="more"
                            id="menu-button"
                            aria-controls={menuOpen ? 'menu-button' : undefined}
                            aria-expanded={menuOpen ? 'true' : undefined}
                            aria-haspopup="true"
                            onClick={handleClickMenu}
                        >
                            <MoreHorizIcon />
                        </IconButton>
                        <Menu
                            id="more-options-menu"
                            MenuListProps={{
                            'aria-labelledby': 'menu-button',
                            }}
                            anchorEl={menuAnchorEl}
                            open={menuOpen}
                            onClose={handleCloseMenu}
                            slotProps={{
                            paper: {
                                style: {
                                maxHeight: ITEM_HEIGHT * 4.5,
                                width: '20ch',
                                },
                            },
                            }}
                        >
                            <MenuItem key={'Remove hilight'} onClick={handleClickRemoveHilight}>
                                Remove hilight
                            </MenuItem>
                            
                        </Menu>
                    </Box>
                }
            </Box>
            
            <Box className='feed-list-item-text'>
                <p className='feed-text-paragraph'>
                {!recognitionData.anonymous && <span className='feed-text-from'>{`From ${recognitionData.userSenderName.firstName} ${recognitionData.userSenderName.lastName} ` + (recognitionData.text ? ' : ' : '')}</span>}
                {recognitionData.text}
                </p>
                <Box className='feed-item-footer'>
                    {
                        certificateVisible && 
                            <Button
                                variant='outlined'
                                size='small'
                                startIcon={<WorkspacePremiumIcon />}
                                onClick={() => setShowCertificatePopup(true)}
                                className='view-certificate-button'
                            > 
                                View Certificate
                            </Button>
                    }
                    <p className='feed-item-time'>{timeString}</p>
                </Box>
                
            </Box>
        </div>

    )
}