import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import * as Sentry from "@sentry/react";

import { authSelector } from '../../../store/authSlice';

import { UserData } from '../../../../../src/types/user';
import { TokenTypeDisplayData } from '../../../../../src/types/recognition';
import { MainServiceApi } from '../../../services/mainService';

import './RecognitionForm.css';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { RecognitionForm } from './RecognitionForm';
import { RecognitionFormType } from './Recognition';

export interface StudentRecognitionFormProps {
    userId: number;
    schoolId: number;
    handleRecognitionFormSubmitted: (result: {success: boolean, bonusPointAwarded?: boolean}) => void;
}

// NOTE - Currently this form is just for one school - we will need to add a school drop down if we want to allow hilighting students across schools in the future
export function StudentRecognitionForm(props: StudentRecognitionFormProps) {
    const {
        userId,
        schoolId,
        handleRecognitionFormSubmitted,
    } = props;
    
    const authDataStore = useSelector(authSelector);
	const { token } = authDataStore;

    const [isLoadingFormFields, setIsLoadingFormFields] = useState(true);
    const [studentList, setStudentList] =  useState<UserData[]>([]);
    const [schoolTokens, setSchoolTokens] = useState<TokenTypeDisplayData[]>([]);

    const loadSchoolData = async (): Promise<void> => {
        setIsLoadingFormFields(true);

        try {
            const mainService = MainServiceApi();
            
            // Get the core values for the school
            const schoolTokens = await mainService.getTokenTypes(schoolId, token!);
            setSchoolTokens(schoolTokens);

            // Get the list of students for the school
            const students = await mainService.getSchoolStudents(schoolId, token!);
            setStudentList(students);

        } catch (err) {
            // log the error
            Sentry.captureException(err);
        } 

        setIsLoadingFormFields(false);
    };

    useEffect(() => {
        loadSchoolData();
    }, [schoolId])

    return (
        <div>
            <RecognitionForm 
                userId={userId}
                handleRecognitionFormSubmitted={handleRecognitionFormSubmitted}
                tokenTypes={schoolTokens}
                schoolStaff={studentList}
                rewardsPointsDisabled={true} // Don't care about points for hilighting students
                recognitionFormType={RecognitionFormType.Student}
                hideAnonymous={true} // Disable the anonymous feature for students
                isLoadingFormData={isLoadingFormFields}
                isForStudents={true}
            />
        </div>

    );
}