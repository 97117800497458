import { useState } from "react";

import { Box, CircularProgress, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { GetAdminDataStaffChart } from "../../../../../../src/types/dataDashboard";
import { CoreValuesChart } from "./CoreValuesChart";

import './StaffCoreValuesChart.css';

export interface StaffCoreValuesChartProps {
    data: GetAdminDataStaffChart,
    dataLoading: boolean,
    chartLegend: {
        id: string,
        color: string,
        value: string
    }[]
}

export function StaffCoreValuesChart(props: StaffCoreValuesChartProps) {
    const {chartLegend, data, dataLoading} = props;

    const [tabValue, setTabValue] = useState('1');

    const staffVisibleCount = 10;

    const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
    	setTabValue(newValue);
  	};

    return (
        <Box className='staff-kreds-chart-box'>
            <TabContext value={tabValue}>
                <Box className='staff-kreds-chart-tabs'>
                    <TabList onChange={handleChangeTab} aria-label="basic tabs example">
                        <Tab label="Hilights Earned" value='1' />
                        <Tab label="Hilights Given" value='2' />
                    </TabList>
                </Box>
                <TabPanel value='1' className='admin-data-tab-panel'>
                    {dataLoading ? <div className='admin-data-loading-progess'><CircularProgress /></div> :
                        <CoreValuesChart
                            data={data.staffTokensEarnedByType}
                            chartLegend={chartLegend}
                            usersVisibleCount={staffVisibleCount}
                        />
                    }
                </TabPanel>
                <TabPanel value='2' className='admin-data-tab-panel'>
                    {dataLoading ? <div className='admin-data-loading-progess'><CircularProgress /></div> :
                        <CoreValuesChart
                            data={data.staffTokensGivenByType}
                            chartLegend={chartLegend}
                            usersVisibleCount={staffVisibleCount}
                        />
                    }
                </TabPanel>
            </TabContext>
        </Box>
    );
}
