import React, { useState, useEffect } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, CircularProgress, Grid, Typography } from '@mui/material';
import * as Sentry from "@sentry/react";
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import RefreshIcon from '@mui/icons-material/Refresh';
import _ from 'lodash';

import { useAppSelector } from '../../../../store/hooks';
import { authSelector } from '../../../../store/authSlice';
import { ManageRewardsTable } from './ManageRewardsTable';
import { ManageRewardData, PurchasedRewardTableDisplayData, UserKredsToRedeemData } from '../../../../../../src/types/rewards';
import { School, UserData } from '../../../../../../src/types/user';
import { MainServiceApi } from '../../../../services/mainService';
import EditRewardPopup from './EditRewardPopup';
import DeactivateRewardPopup from './DeactivateRewardPopup';
import { PermissionServiceApi } from '../../../../services/permissionService';
import { RedeemedRewardsTable } from './RedeemedRewardsTable';
import { StaffPointsTable } from './StaffPointsTable';

import './ManageRewardsTab.css';

export interface ManageRewardsTabProps {
    districtId: number;
    currentSchoolId: number;
    rewardsChanged?: () => void; // Used by Rewards tab
}

export function ManageRewardsTab(props: ManageRewardsTabProps) {
    const { districtId, rewardsChanged, currentSchoolId } = props;
    const authDataStore = useAppSelector(authSelector);
    const {token} = authDataStore;

    const [pageDataLoading, setPageDataLoading] = useState(false);
    const [districtSchools, setDistrictSchools] = useState<School[]>([]);
    const [activeRewards, setActiveRewards] = useState<ManageRewardData[]>([]);
    const [deactivatedRewards, setDeactivatedRewards] = useState<ManageRewardData[]>([]);
    const [districtRewardOwners, setDistrictRewardOwners] = useState<UserData[]>([]);
    const [districtUsers, setDistrictUsers] = useState<UserData[]>([]);

    // Default reward image
    const [defaultImageData, setDefaultImageData] = useState<{imagePath: string, imageUrl: string}>();

    // Permissions
    const [permissionsLoading, setPermissionsLoading] = useState(true);
    const [rewardsDisabled, setRewardsDisabled] = useState(false);

    useEffect(() => {
		if (districtId) {
			getDistrictAndSchoolLevelPermissions(districtId, currentSchoolId);
		}
	}, [districtId, currentSchoolId]);

    const getDistrictAndSchoolLevelPermissions = async (districtId: number, schoolId: number) => {
		const permissionsServiceApi = PermissionServiceApi();
        setPermissionsLoading(true);
		try {
			const districtPermissions = await permissionsServiceApi.getPermissionsForDistrict(districtId, token!);
            const schoolPermissions =  await permissionsServiceApi.getPermissionsForSchool(schoolId, token!)

			// Should the district feed be visible? (district-level permission)
			const hideRewardsKey = 'HIDE_REWARDS';
			const hideRewardsForDistrict = districtPermissions.find(permission => permission.permissionKey === hideRewardsKey);
            const hideRewardsForSchool = schoolPermissions.find(permission => permission.permissionKey === hideRewardsKey);
			if (hideRewardsForDistrict || hideRewardsForSchool) {
				setRewardsDisabled(true);
			} else {
				setRewardsDisabled(false);
			}
		} catch (err) {
			// log the error
			Sentry.captureException(err);
		}
        setPermissionsLoading(false);
	}

    // Load the data for the reward tables
    useEffect(() => {
        loadTableData(districtId);
    }, [districtId]);

    const loadTableData = async (districtId: number) => {
        setPageDataLoading(true);
        try {
            const mainServiceApi = MainServiceApi();
            const rewardsData = await mainServiceApi.getDistrictRewards({districtId, token: token!});
            if (rewardsData) {
                setActiveRewards(_.sortBy(rewardsData.activeRewards, ['schoolId', 'cost']));
                setDeactivatedRewards(rewardsData.deactivatedRewards);
                setDistrictRewardOwners(rewardsData.districtRewardOwners);
            } else {
                setActiveRewards([]);
                setDeactivatedRewards([]);
                setDistrictRewardOwners([]);
            }

            const schools = await mainServiceApi.getDistrictSchools(districtId, token!);
            if (schools) {
                setDistrictSchools(schools)
            } else {
                setDistrictSchools([]);
            }

            const districtUsers = await mainServiceApi.getDistrictUsers({districtId, token: token!});
            if (districtUsers) {
                setDistrictUsers(districtUsers);
            } else {
                setDistrictUsers([]);
            }

            const defaultRewardImageData: {imagePath: string, imageUrl: string} = await mainServiceApi.getDefaultRewardImage(token!);
            setDefaultImageData(defaultRewardImageData);

        } catch (err) {
            // log the error
            Sentry.captureException(err);
        }
        setPageDataLoading(false);
    }

    // Table Action Popups:
    const [selectedReward, setSelectedReward] = useState<ManageRewardData | undefined>();
    const [showEditRewardPopup, setShowEditRewardPopup] = useState(false);
    const [showDeactivateRewardPopup, setShowDeactivateRewardPopup] = useState(false);
    const [showReactivateRewardPopup, setShowReactivateRewardPopup] = useState(false);
    const handleClickActions = (data: {reward?: ManageRewardData, deactivate?: boolean, reactivate?: boolean}) => {
        setSelectedReward(data.reward);
        if (data.deactivate) {
            setShowDeactivateRewardPopup(true);
        } else if (data.reactivate) {
            setShowReactivateRewardPopup(true);
        } else {
            setShowEditRewardPopup(true);
        }
    }
    const closePopup = () => {
        setSelectedReward(undefined);
        // We can use the same function for all three popups, so just close all
        setShowEditRewardPopup(false);
        setShowDeactivateRewardPopup(false);
        setShowReactivateRewardPopup(false);
    }

    const handleResetUpdatedReward = (data: {reward: ManageRewardData, newlyCreatedReward: boolean}) => {
        // If the reward was created or updated, we need to update the Currrent Rewards list
        // We can just add it manually and not re-get the data if we have all the data we need
        // but if a new reward owner was added that hasn't been used in the table yet, then we need to re-get the data
        const rewardOwner = districtRewardOwners.find(user => user.id === data.reward.ownerUserId)
        if (!rewardOwner) {
            loadTableData(districtId);
        } else {
            //otherwise we can just update the single row in the table

            // if the reward was created
            if (data.newlyCreatedReward) {
                const newRows = activeRewards.concat(data.reward);
                setActiveRewards(_.sortBy(newRows, ['schoolId', 'cost' ]));
            } else { // or if the reward was updated
                // Update the row for the reward so it shows the new data
                const newRows = activeRewards.map((row) => {
                    if (row.id === data.reward.id) {
                        return data.reward;
                    } else {
                        return row;
                    }
                });
                setActiveRewards(_.sortBy(newRows, ['schoolId', 'cost' ]));
            }
        }
        
        if (rewardsChanged) {
            rewardsChanged();
        }
    }

    const handleResetDeactivatedReward = (deactivatedReward: ManageRewardData) => {
        // Remove the deactivated reward from the Current Rewards table
        const newRows = activeRewards.filter(row => row.id !== deactivatedReward.id);
        setActiveRewards(newRows);
        
        // Add the deactivated reward to the Deactivated Rewards table and sort by school then cost
        const newDeactivatedRewards = deactivatedRewards.concat(deactivatedReward);
        setDeactivatedRewards(_.sortBy(newDeactivatedRewards, ['schoolId', 'cost']));
        if (rewardsChanged) {
            rewardsChanged();
        }
    }

    const handleResetReactivatedReward = (data: {reward: ManageRewardData, newlyCreatedReward: boolean}) => {
        // Remove the reactivated reward from the Deactivated Rewards table
        const newDeactivatedRewards = deactivatedRewards.filter(row => row.id !== data.reward.id);
        setDeactivatedRewards(newDeactivatedRewards);

        // Add the reactivated reward to the Current Rewarrds table and sort by school then cost
        const newActiveRewards = activeRewards.concat(data.reward);
        setActiveRewards(_.sortBy(newActiveRewards, ['schoolId', 'cost']));
        if (rewardsChanged) {
            rewardsChanged();
        }
    }

    // Rewards accordion states
    // const expandText = <span>{'Expand (click '}<ExpandMoreIcon sx={{fontSize: 'small'}}/>{') to view'}</span>
    const [panel1Open, setPanel1Open] = useState(false);
    const panel1Text = (
        <Typography className='current-rewards-instructions'>
            <span>
                {!panel1Open ? 'Expand to view' : 'View'} the list of rewards available to your staff and 
                <span className='create-users-bold-text'> Update </span>{`(`}<EditIcon sx={{fontSize: 'small'}}/>{`) or `}<span className='create-users-bold-text'>Deactivate</span>{` (`}<DeleteIcon sx={{fontSize: 'small'}}/>{`) `}
                rewards as needed.
            </span>
        </Typography>
    );
    const [panel2Open, setPanel2Open] = useState(false);
    const panel2Text = (
        <Typography className='current-users-instructions'>
            <span>
                {!panel2Open ? 'Expand to view' : 'View'} and
                <span className='create-users-bold-text'> Reactivate </span>{`(`}<RefreshIcon sx={{fontSize: 'small'}}/>{`) `}
                any rewards that were previously deactivated if you'd like to make them available to your staff again.
            </span>
        </Typography>
    );
    // Staff points
    const [panel3Open, setPanel3Open] = useState(false);
    const [staffPointsRows, setStaffPointsRows] = useState<UserKredsToRedeemData[]>([]);
    const [staffPointsLoading, setStaffPointsLoading] = useState(true);
    const handleChangeOpenStaffPointsLoading = async (expanded: boolean) => {
        if (expanded && token) {
            try {
                setStaffPointsLoading(true);
                const mainServiceApi = MainServiceApi();
                const staffPointsResult = await mainServiceApi.getDistrictPointsToRedeem({districtId, token});
                setStaffPointsRows(staffPointsResult.userPointsToRedeemData);
            } catch (err) {
                // log the error
                Sentry.captureException(err);
            }
            
            setStaffPointsLoading(false);
        }
    }
    const panel3Text = (
        <Typography className='current-users-instructions'>
            <span>{!panel3Open ? 'Expand to view' : 'View'} your list of staff and the current number of points they each have to redeem in the rewards marketplace. 
            You can use this information to adjust the price of your rewards, although we do not recommend raising the cost of a reward mid-school year.
            You can lower the costs of rewards to encourage your staff to redeem their points!</span>
        </Typography>
    );

    //Redeemed Rewards
    const [panel4Open, setPanel4Open] = useState(false);
    const [redeemedRewardsRows, setRedeemedRewardsRows] = useState<PurchasedRewardTableDisplayData[]>([]);
    const [redeemedRewardsLoading, setRedeemedRewardsLoading] = useState(true);
    const handleChangeOpenRedeemedRewards = async (expanded: boolean) => {
        if (expanded && token) {
            try {
                setRedeemedRewardsLoading(true);
                const mainServiceApi = MainServiceApi();
                const redeemedRewardsResult = await mainServiceApi.getDistrictRedeemedRewards({districtId, token});
                setRedeemedRewardsRows(redeemedRewardsResult.redeemedRewards);
            } catch (err) {
                // log the error
                Sentry.captureException(err);
            }
            
            setRedeemedRewardsLoading(false);
        }
    }
    const panel4Text = (
        <Typography className='current-users-instructions'>
            <span>{!panel4Open ? 'Expand to view' : 'View'} the list of rewards that your staff has redeemed.</span>
        </Typography>
    )

    if (permissionsLoading) {
        return(
            <Box className='reward-permmissions-progress'><CircularProgress /></Box>
        )
    }

    if (rewardsDisabled) {
        return (
            <Box>
                <Typography>Rewards have been disabled for your school district. Please reach out to the Support Team if you would like to enable Rewards!</Typography>
            </Box>
        )
    }

    return (
        <Box>
            <EditRewardPopup 
                rewardToUpdate={selectedReward}
                districtSchools={districtSchools}
                districtUsers={districtUsers}
                districtId={districtId}
                popupOpen={showEditRewardPopup} 
                closePopup={closePopup}
                resetReward={handleResetUpdatedReward}
                reactivateReward={false}
                defaultImageData={defaultImageData}
            />
            <DeactivateRewardPopup 
                rewardToDeactivate={selectedReward}
                popupOpen={showDeactivateRewardPopup}
                closePopup={closePopup}
                handleRemoveReward={handleResetDeactivatedReward}
            />
            {/* Reactivate Reward Popup (also uses Edit Rewarrd Popup) */}
            <EditRewardPopup 
                rewardToUpdate={selectedReward}
                districtSchools={districtSchools}
                districtUsers={districtUsers}
                districtId={districtId}
                popupOpen={showReactivateRewardPopup} 
                closePopup={closePopup}
                resetReward={handleResetReactivatedReward}
                reactivateReward={true}
                defaultImageData={defaultImageData}
            />
            <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                    <div className='reward-admin-settings-grid-item-border'>
                        <Accordion 
                            className='rewards-accordian'
                            onChange={(event, expanded) => {
                                setPanel1Open(expanded);
                            }}
                            disableGutters={true}
                        >
                                <AccordionSummary 
                                    expandIcon={<ExpandMoreIcon />} 
                                    aria-controls="panel1-content"
                                    id="panel1-header"
                                    className='rewards-accordian-summary'
                                >
                                    <Box className='current-rewards-header'>
                                        <h3 className='reward-admin-settings-grid-item-header'>Current Rewards</h3>
                                        <div className='create-reward-button-div'>
                                            <Button 
                                                id='create-reward-button' 
                                                variant='contained'
                                                startIcon={<AddIcon />}
                                                onClick={() => handleClickActions({})}
                                            >Create Reward</Button>
                                        </div>
                                    </Box>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div>
                                        {panel1Text}
                                        <ManageRewardsTable 
                                            districtRewardOwners={districtRewardOwners}
                                            districtSchools={districtSchools}
                                            rewardRows={activeRewards}
                                            activeRewards={true}
                                            rowsLoading={pageDataLoading}
                                            handleClickAction={handleClickActions}
                                        />
                                    </div>
                                </AccordionDetails>
                        </Accordion>
                        {!panel1Open && panel1Text}
                    </div>
                </Grid>
                <Grid item xs={12} md={12} className='admin-settings-grid-item'>
                    <div className='reward-admin-settings-grid-item-border-no-button'>
                        <Accordion 
                            className='rewards-accordian'
                            onChange={(event, expanded) => {
                                setPanel2Open(expanded);
                            }}
                            disableGutters={true}
                        >
                            <AccordionSummary 
                                expandIcon={<ExpandMoreIcon />} 
                                aria-controls="panel2-content"
                                id="panel2-header"
                                className='rewards-accordian-summary'
                            >
                                <h3 className='reward-admin-settings-grid-item-header-no-button'>Deactivated Rewards</h3>
                            </AccordionSummary>
                            <AccordionDetails>
                                {panel2Text}
                                <ManageRewardsTable 
                                    districtRewardOwners={districtRewardOwners}
                                    districtSchools={districtSchools}
                                    rewardRows={deactivatedRewards}
                                    activeRewards={false}
                                    rowsLoading={pageDataLoading}
                                    handleClickAction={handleClickActions}
                                />
                            </AccordionDetails>
                        </Accordion>
                        {!panel2Open && panel2Text}
                    </div>
                </Grid>
                <Grid item xs={12} md={12} className='admin-settings-grid-item'>
                    <div className='reward-admin-settings-grid-item-border-no-button'>
                        <Accordion 
                            className='rewards-accordian'
                            onChange={(event, expanded) => {
                                setPanel4Open(expanded);
                                handleChangeOpenRedeemedRewards(expanded);
                            }}
                            disableGutters={true}
                        >
                            <AccordionSummary 
                                expandIcon={<ExpandMoreIcon />} 
                                aria-controls="panel4-content"
                                id="panel4-header"
                                className='rewards-accordian-summary'
                            >
                                <h3 className='reward-admin-settings-grid-item-header-no-button'>Redeemed Rewards</h3>
                            </AccordionSummary>
                            <AccordionDetails>
                                {panel4Text}
                                <RedeemedRewardsTable 
                                   redeemedRewardsRows={redeemedRewardsRows}
                                   rowsLoading={redeemedRewardsLoading}
                                />
                            </AccordionDetails>
                        </Accordion>
                        {!panel4Open && panel4Text}
                    </div>
                </Grid>
                <Grid item xs={12} md={12} className='admin-settings-grid-item'>
                    <div className='reward-admin-settings-grid-item-border-no-button'>
                        <Accordion 
                            className='rewards-accordian'
                            onChange={(event, expanded) => {
                                setPanel3Open(expanded);
                                handleChangeOpenStaffPointsLoading(expanded);
                            }}
                            disableGutters={true}
                        >
                            <AccordionSummary 
                                expandIcon={<ExpandMoreIcon />} 
                                aria-controls="panel3-content"
                                id="panel3-header"
                                className='rewards-accordian-summary'
                            >
                                <h3 className='reward-admin-settings-grid-item-header-no-button'>Your Staff's Reward Points</h3>
                            </AccordionSummary>
                            <AccordionDetails>
                                {panel3Text}
                                <StaffPointsTable
                                    staffPointsRows={staffPointsRows}
                                    rowsLoading={staffPointsLoading}
                                />
                            </AccordionDetails>
                        </Accordion>
                        {!panel3Open && panel3Text}
                    </div>
                </Grid>
            </Grid>
        </Box>
    )
}